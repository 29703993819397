<template>
    <div>Esporta Utenti</div>
</template>

<script>
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Utenti", route: "/security/users/index" },
             { title: "Esporta Utenti" }
         ]);
     }
 };
</script>
